@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  outline: none !important;
}
.ck-editor__editable {
  max-height: 500px;
  max-width: 650px;
}

.loadingCtn {
  @apply flex fixed right-0 left-0 z-20 top-0 min-h-screen bg-white bg-opacity-100;
}
.lf-player-container {
  @apply inline-block m-auto w-1/6 overflow-hidden;
}
